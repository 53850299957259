import type { Feature } from '@getstep/sdk/dist/api/generated/FeatureProto'
import type { LiteralUnion } from 'type-fest'

import type { WithTestId } from './testid'

export const PAGE_OPTIONS: Record<string, PageOption> = {
    ERROR_404: {
        slug: '404',
        meta: { name: '404' },
    },
    ERROR_500: {
        slug: '500',
        meta: { name: '500' },
    },
    ERROR_504: {
        slug: '504',
        meta: { name: '504' },
    },
    STEP_BLACK: {
        slug: 'blackcard',
        meta: { name: 'Step Black' },
    },
    SPONSORSHIP_OFFER: {
        slug: 'sponsorshipoffer',
        meta: {
            name: 'Sponsorship Offer',
            feature: 'get-app-button-sponsorshipoffer',
        },
    },
    SPONSOR_REQUEST: {
        slug: 'sponsorrequest',
        meta: {
            name: 'Sponsor Request',
            feature: 'get-app-button-sponsorrequest',
        },
    },
    SECURITY: {
        slug: 'security',
        meta: {
            name: 'Security',
        },
    },
    PARENTS: {
        slug: 'parents',
        meta: { name: 'Parents' },
    },
    HOME: {
        slug: 'home',
        meta: { name: 'Home', feature: 'get-app-button-homepage' },
    },
    FAQ: {
        slug: 'faq',
        meta: { name: 'FAQ' },
    },
    ABOUT: {
        slug: 'about',
        meta: { name: 'About' },
    },
    FOR_PARENTS: { slug: 'for-parents', meta: { name: 'For Parents' } },
    BUILD_CREDIT: { slug: 'build-credit', meta: { name: 'Build Credit' } },
    SAVE: { slug: 'save', meta: { name: 'Save' } },
    SAVINGS: { slug: 'savings', meta: { name: 'Savings' } },
    EARN_REWARDS: { slug: 'earn-rewards', meta: { name: 'Earn Rewards' } },
    ALLOWANCE: { slug: 'allowance', meta: { name: 'Allowance' } },
    REWARDS: { slug: 'rewards', meta: { name: 'Rewards' } },
    CONVENIENCE: { slug: 'convenience', meta: { name: 'Convenience' } },
    BORROW: { slug: 'borrow', meta: { name: 'Borrow' } },
    EDUCATION: {
        slug: 'education',
        meta: { name: 'Education' },
    },
    MOVE_MONEY: {
        slug: 'move-money',
        meta: { name: 'Move Money' },
    },
    CREDIT_HISTORY: {
        slug: 'credit-history',
        meta: { name: 'Credit History' },
    },
    CAREERS: {
        slug: 'careers',
        meta: { name: 'Careers' },
    },
    CAREER_JOB: {
        slug: 'careers/[id]',
        meta: { name: 'Job posting' },
    },
    MONEY_101: {
        slug: 'money-101',
        meta: { name: 'Money 101' },
    },
    MONEY_101_POST: {
        slug: 'money-101/post/[slug]',
        meta: { name: 'Money 101 Post' },
    },
    MONEY_101_TOPIC: {
        slug: 'money-101/[slug]',
        meta: { name: 'Money 101 Topic' },
    },
    PRESS: {
        slug: 'press',
        meta: { name: 'Press' },
    },
    SEND_MONEY: {
        slug: 'send-money',
        meta: {
            name: 'Send money',
            feature: 'get-app-button-send-money',
        },
    },
    REQUEST_MONEY: {
        slug: 'request-money',
        meta: {
            name: 'Request money',
            feature: 'get-app-button-request-money',
        },
    },
    PAY: {
        slug: 'pay/[tr]',
        meta: {
            name: 'Pay',
            feature: 'get-app-button-request-money',
        },
    },
    PAY_WITH_CARD: {
        slug: 'pay/[tr]/input',
        meta: {
            name: 'Pay with Card',
            feature: 'get-app-button-request-money',
        },
    },
    CONFIRM_PAYMENT: {
        slug: 'pay/[tr]/confirmation',
        meta: {
            name: 'Confirm payment',
            feature: 'get-app-button-request-money',
        },
    },
    PAYMENT_SUCCESS: {
        slug: 'pay/[tr]/success',
        meta: {
            name: 'Payment Success',
            feature: 'get-app-button-request-money',
        },
    },
    PAYMENT_FAIL: {
        slug: 'pay/[tr]/fail',
        meta: {
            name: 'Payment Fail',
            feature: 'get-app-button-request-money',
        },
    },
    PAYING: {
        slug: 'pay/[tr]/paying',
        meta: {
            name: 'Paying',
            feature: 'get-app-button-request-money',
        },
    },
    PROFILE_PAY: {
        slug: '$/[username]/pay',
        meta: {
            name: 'Profile Pay',
            feature: 'get-app-button-profile-pay',
        },
    },
    FUNDING_AMOUNT: {
        slug: 'funding/[id]/amount',
        meta: { name: 'Funding Amount' },
    },
    FUNDING_CARD: {
        slug: 'funding/[id]/input',
        meta: { name: 'Funding Card' },
    },
    FUNDING_CONFIRMATION: {
        slug: 'funding/[id]/review',
        meta: { name: 'Funding Confirmation' },
    },
    FUNDING_PROCESSING: {
        slug: 'funding/[id]/processing',
        meta: { name: 'Funding Processing' },
    },
    FUNDING_SUCCESS: {
        slug: 'funding/[id]/success',
        meta: { name: 'Funding Success' },
    },
    FUNDING_COMPLETE: {
        slug: 'funding/[id]/complete',
        meta: { name: 'Funding Complete' },
    },
    FUNDING_FAIL: {
        slug: 'funding/[id]/fail',
        meta: { name: 'Funding Fail' },
    },
    FUNDING_PENDING: {
        slug: 'funding/[id]/pending',
        meta: { name: 'Funding Pending' },
    },
    FUNDING_EXPIRED: {
        slug: 'funding/expired',
        meta: { name: 'Funding Expired' },
    },
    REFERRAL: {
        slug: 'referral/[code]',
        meta: { name: 'Referral', feature: 'get-app-button-referral' },
    },
    POLICIES: {
        slug: 'policies',
        meta: { name: 'Policies' },
    },
    OFFERS: {
        slug: 'offers/[code]',
        meta: { name: 'Offers', feature: 'get-app-button-offers' },
    },
    FEATURES: {
        slug: 'features/[name]',
        meta: { name: 'Features' },
    },
    REFERRAL_INVALID: {
        slug: 'referral/[code]/invalid',
        meta: { name: 'Referral' },
    },
    USER: {
        slug: '$/[username]',
        meta: { name: 'Public Profile', feature: 'get-app-button-user' },
    },
    LOGIN: {
        meta: { name: 'Sign in' },
        slug: 'login',
    },
    LOGIN_VERIFY: {
        meta: { name: 'Enter Phone Code' },
        slug: 'login/verify',
    },
    SSO_EMAIL: {
        meta: { name: 'Enter Email' },
        slug: 'sso/email',
    },
    SSO_EMAIL_VERIFY: {
        meta: { name: 'Enter Email' },
        slug: 'sso/email/verify',
    },
    SSO_EMAIL_VERIFY_FAILED: {
        meta: { name: 'Email Verification Failed' },
        slug: 'sso/email/verify/failed',
        'data-testid': 'login.email.failed',
    },
    SSO_EMAIL_VERIFY_PASSED: {
        meta: { name: 'Email Verification Passed' },
        slug: 'sso/email/verify/success',
        'data-testid': 'login.email.success',
    },
    SSO_EMAIL_VERIFYING: {
        meta: { name: 'Email Verification' },
        slug: 'sso/email/verify/loading',
        'data-testid': 'login.email.loading',
    },
    SSO_APPLE_VERIFY: {
        meta: { name: 'Verifying Apple ID' },
        slug: 'sso/apple/verify',
    },
    SSO_APPLE_VERIFY_FAILED: {
        meta: { name: 'Apple ID Verification Failed' },
        slug: 'sso/apple/verify/failed',
    },
    SSO_APPLE_VERIFY_PASSED: {
        meta: { name: 'Apple ID Verification Success' },
        slug: 'sso/apple/verify/success',
    },
    SSO: {
        meta: { name: 'Single sign-on' },
        slug: 'sso',
    },
    SSO_LEARN_WORLDS: {
        meta: { name: 'Enter Phone' },
        slug: 'sso/lw',
    },
    SSO_LEARN_WORLDS_VERIFY: {
        meta: { name: 'Enter Code' },
        slug: 'sso/lw/verify',
    },
    KYC: {
        meta: { name: 'Verify your identity' },
        slug: 'identity',
    },
    KYC_COMBINED: {
        meta: { name: 'Verify your identity' },
        slug: 'identity-combined',
    },
    KYC_ID_CHECK: {
        meta: { name: 'ID Check' },
        slug: 'identity-check',
    },
    KYC_SUCCESS: {
        slug: 'identity/success',
        meta: { name: 'Success' },
    },
    KYC_SUCCESS_SOLO: {
        slug: 'identity/success-solo',
        meta: { name: 'Success' },
    },
    KYC_FAILED: {
        meta: { name: 'Failed' },
        slug: 'identity/failed',
    },
    KYC_PROCESSING: {
        meta: { name: 'Processing' },
        slug: 'identity/processing',
    },
    ADMIN_OFFER: {
        meta: { name: 'Admin offer' },
        slug: 'adminoffer',
    },
    INVESTING: {
        slug: 'investing',
        meta: {
            name: 'Investing',
        },
    },
    INVESTING_FOR_PARENTS: {
        slug: 'investing-for-parents',
        meta: {
            name: 'Investing for Parents',
        },
    },
    SCHOOL_REQUEST: {
        slug: 'school-request',
        meta: {
            name: 'School Request',
            feature: 'get-app-button-school-request-waitlist',
        },
    },
    SCHOOL_REQUEST_WAITLIST_SUCCESS: {
        slug: 'school-request/success',
        meta: {
            name: 'School Request Waitlist Success',
            feature: 'get-app-button-school-request-waitlist',
        },
    },
    SCHOOL_REQUEST_WAITLIST_FAIL: {
        slug: 'school-request/fail',
        meta: {
            name: 'School Request Waitlist Fail',
            feature: 'get-app-button-school-request-waitlist',
        },
    },
    CREATOR_PROGRAM: {
        slug: 'creatorprogram',
        meta: {
            name: 'Step Creator Program Application',
        },
    },
    SCAN: {
        slug: 'scan',
        meta: { name: 'Scan' },
    },
    COOKIE_LIST: {
        slug: 'cookie-declaration',
        meta: { name: 'Cookie Declaration' },
    },
    DEVICE: {
        slug: 'd/[id]',
        meta: { name: 'New Device Login' },
    },
} as const

export const KYC_PAGES = [
    PAGE_OPTIONS.LOGIN,
    PAGE_OPTIONS.LOGIN_VERIFY,
    PAGE_OPTIONS.SSO_EMAIL,
    PAGE_OPTIONS.SSO_EMAIL_VERIFY,
    PAGE_OPTIONS.SSO,
    PAGE_OPTIONS.KYC,
    PAGE_OPTIONS.KYC_FULL_SSN,
    PAGE_OPTIONS.KYC_SUCCESS,
    PAGE_OPTIONS.KYC_FAILED,
]

export const PAYMENT_FUNNEL_PAGES = [
    PAGE_OPTIONS.PAYMENT_SUCCESS,
    PAGE_OPTIONS.PAYMENT_FAIL,
    PAGE_OPTIONS.PAYING,
    PAGE_OPTIONS.CONFIRM_PAYMENT,
    PAGE_OPTIONS.PAY_WITH_CARD,
]

export const FUNDING_PAGES = [
    PAGE_OPTIONS.FUNDING_AMOUNT,
    PAGE_OPTIONS.FUNDING_CARD,
    PAGE_OPTIONS.FUNDING_CONFIRMATION,
]

export const getPageOptionBySlug = (slug: string) => {
    const page = Object.values(PAGE_OPTIONS).find(
        ({ slug: pageSlug }) => pageSlug === slug
    )
    return page ?? null
}

export function hasSlugs(
    pathname: string,
    configs: (LiteralUnion<PageSlug, string> | PageOptions)[]
) {
    return configs.some((config) => {
        const slug: string = typeof config === 'string' ? config : config.slug
        return pathname?.startsWith(`/${slug}`)
    })
}

export const isHomePage = (pathname: string) =>
    pathname === '/' || pathname === ''

export const isKYCPage = (pathname: string) => hasSlugs(pathname, KYC_PAGES)

export const isFundingPage = (pathname: string) =>
    hasSlugs(pathname, FUNDING_PAGES)

const PAYMENT_FUNNEL_REGEX = /\/pay\/tr(:|%3A).+\/.+/
export const isPaymentFunnelPage = (pathname: string) =>
    PAYMENT_FUNNEL_REGEX.test(pathname)

export type PageKeys = keyof typeof PAGE_OPTIONS
export type PageOptions<K extends PageKeys = PageKeys> =
    (typeof PAGE_OPTIONS)[K]
export type PageSlug<K extends PageKeys = PageKeys> = PageOptions<K>['slug']
export type PageName = PageOptions['meta']['name']

export interface PageOption extends WithTestId {
    /** URL identifier */
    slug: string
    meta: {
        /** Name of the page, needed for tracking */
        name: string
        feature?: string
    }
    feature?: Feature
}
